//scp -r build/* node@node.alma.fr:/var/www/html/wonderwall/alpha
import React, { Component, Fragment } from 'react'
import {
  List,
  Image,
  Container,
  Button,
  Popup,
  Responsive,
  Form,
  Message,
  Confirm,
  Embed
} from 'semantic-ui-react'
import Mosaic from './Mosaic'
import { proxyfy, updatePost, publishPost, deletePost } from '../Services/Api'
import Joyride from 'react-joyride'
import _ from 'lodash'
import moment from 'moment'
import Linkify from 'linkifyjs/react'
import 'moment/locale/fr'
moment.locale('fr')

class Post extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTourOpen: false,
      steps: [
        {
          target: '.info',
          content: `Ce point d'exclamation indique une publication en attente de votre validation`
        },
        {
          target: '.edit',
          content: `Le crayon permet la modification de la publication`
        },
        {
          target: '.check',
          content: `La coche permet la validation de la publication en attente`
        }
      ],
      editMode: false,
      canEdit: false,
      postContentText: '',
      postContentTextLength: 0,
      postContentTextLengthMax: 300,
      updatedDescription: null,
      pending: null,
      showError: false,
      showConfirm: false,
      editDelayPassed: true
    }
  }
  closeTour = () => {
    this.setState({ isTourOpen: false })
  }
  openTour = () => {
    this.setState({ isTourOpen: true })
  }
  showConfirm = () => this.setState({ showConfirm: true })
  handleConfirm = itemId => {
    this.call_deletePost(itemId)
    this.setState({ result: 'confirmed', showConfirm: false })
  }
  handleCancel = () => this.setState({ showConfirm: false })

  componentDidMount() {
    const userGroups = this.props.userGroups
    let canEdit = this.state.canEdit
    let editDelayPassed = this.state.editDelayPassed
    if (userGroups && _.find(userGroups, { Title: this.props.item.Service })) {
      canEdit = true
    }
    var editLimit_date = moment(this.props.item.Created).add(1, 'days')
    if (moment().isBefore(editLimit_date)) {
      editDelayPassed = false
    }

    if (userGroups && _.find(userGroups, { Title: 'com' })) {
      canEdit = true
      editDelayPassed = false
    }

    this.setState({
      postContentText: this.props.item.Description,
      postContentTextLength: this.props.item.Description.length,
      canEdit,
      editDelayPassed
    })
  }
  edit = () => {
    this.setState({ editMode: true })
  }
  onFieldPostContentTextChange = e => {
    var textContent = e.target.value
    var textContentLength = textContent.length
    if (textContentLength > this.state.postContentTextLengthMax) {
      return
    }
    this.setState({
      postContentText: e.target.value,
      postContentTextLength: textContentLength,
      showError: false
    })
  }
  call_updatePost(itemId) {
    if (this.state.postContentText) {
      updatePost(itemId, this.state.postContentText).then(() => {
        this.setState({
          updatedDescription: this.state.postContentText,
          editMode: false
        })
      })
    } else {
      this.setState({ showError: true })
    }
  }
  call_deletePost = itemId => {
    this.props.removePost()
    deletePost(itemId).then(() => {
      this.props.removePost()
      //	this.setState({updatedDescription:this.state.postContentText, editMode: false });
    })
  }

  call_publish(itemId) {
    if (this.state.postContentText) {
      publishPost(itemId).then(() => {
        this.setState({ editMode: false, pending: false })
      })
    }
  }

  render() {
    const { item } = this.props
    if (item.Publi_x00e9_ !== true && !this.state.canEdit) {
      return false
    }

    return (
      <Fragment>
        <List.Item
          className={
            item.Publi_x00e9_ !== true && this.state.pending !== false
              ? 'pending'
              : ''
          }
        >
          <Confirm
            open={this.state.showConfirm}
            className="confirm-modal"
            content="Vous êtes sur le point de supprimer cette publication !"
            cancelButton="Annuler"
            confirmButton="Supprimer"
            onCancel={this.handleCancel}
            onConfirm={() => this.handleConfirm(item.Id)}
            size="tiny"
          />
          {item.Publi_x00e9_ !== true && this.state.pending !== false && (
            <Container textAlign="right" className="toolbar">
              <Popup
                trigger={
                  <Button
                    circular
                    basic
                    inverted
                    icon="question"
                    size="mini"
                    className="help"
                    onClick={() => this.openTour()}
                  />
                }
                content="Afficher l'aide"
              />
              <Popup
                trigger={
                  <Button
                    circular
                    basic
                    inverted
                    icon="exclamation"
                    size="mini"
                    className="info"
                  />
                }
                content="Publication en attente de validation"
              />
              {this.state.canEdit && !this.state.editMode && (
                <Fragment>
                  <Popup
                    trigger={
                      <Button
                        circular
                        basic
                        icon="pencil"
                        size="mini"
                        className="edit"
                        onClick={this.edit}
                      />
                    }
                    content="Modifier cette publication"
                  />
                  <Popup
                    trigger={
                      <Button
                        circular
                        basic
                        icon="check"
                        size="mini"
                        className="check"
                        onClick={() => this.call_publish(item.Id)}
                      />
                    }
                    content="Valider la publication"
                  />
                  <Popup
                    trigger={
                      <Button
                        circular
                        basic
                        icon="delete"
                        size="mini"
                        className="remove"
                        onClick={this.showConfirm}
                      />
                    }
                    content="Supprimer cette publication"
                  />
                </Fragment>
              )}
              {this.state.canEdit && this.state.editMode && (
                <Popup
                  trigger={
                    <Button
                      circular
                      basic
                      icon="save"
                      size="mini"
                      className="check"
                      onClick={() => this.call_updatePost(item.Id)}
                    />
                  }
                  content="Enregistrer les modifications"
                />
              )}
            </Container>
          )}
          {this.state.canEdit &&
            !this.state.editMode &&
            (item.Publi_x00e9_ === true || this.state.pending === false) &&
            !this.state.editDelayPassed && (
              <Container textAlign="right" className="toolbar">
                <Popup
                  trigger={
                    <Button
                      circular
                      basic
                      icon="pencil"
                      size="mini"
                      className="edit"
                      onClick={this.edit}
                    />
                  }
                  content="Modifier cette publication"
                />
                <Popup
                  trigger={
                    <Button
                      circular
                      basic
                      icon="delete"
                      size="mini"
                      className="remove"
                      onClick={this.showConfirm}
                    />
                  }
                  content="Supprimer cette publication"
                />
              </Container>
            )}
          {this.state.canEdit &&
            this.state.editMode &&
            (item.Publi_x00e9_ === true || this.state.pending === false) && (
              <Container textAlign="right" className="toolbar">
                <Popup
                  trigger={
                    <Button
                      circular
                      basic
                      icon="save"
                      size="mini"
                      className="check"
                      onClick={() => this.call_updatePost(item.Id)}
                    />
                  }
                  content="Enregistrer les modifications"
                />
              </Container>
            )}
          <Image
            avatar
            src={this.props.serviceLogos[item.Service]}
            size="tiny"
          />
          <List.Content className={this.state.editMode ? 'edit' : ''}>
            <List.Header className={'color-' + item.Service}>
              {item.Title}
              <span className="date">
                {moment(item.Date)
                  .locale('fr')
                  .local()
                  .format('DMMYYYY') ===
                moment()
                  .locale('fr')
                  .local()
                  .format('DMMYYYY')
                  ? moment(item.Date)
                      .locale('fr')
                      .local()
                      .fromNow()
                  : moment(item.Date).format('YYYY') === moment().format('YYYY')
                  ? moment(item.Date)
                      .locale('fr')
                      .local()
                      .format('D MMM')
                  : moment(item.Date)
                      .locale('fr')
                      .local()
                      .format('D MMM YYYY')}
              </span>
            </List.Header>

            <List.Description
              style={{ whiteSpace: 'pre-line', wordWrap: 'normal' }}
            >
              {!this.state.editMode && (
                <Fragment>
                  <Linkify>
                    {this.state.updatedDescription || item.Description}
                  </Linkify>
                </Fragment>
              )}

              {this.state.editMode && (
                <Form>
                  <Form.TextArea
                    placeholder="Quoi de neuf ?"
                    onChange={this.onFieldPostContentTextChange}
                    value={this.state.postContentText}
                  />
                  {this.state.showError && (
                    <Message negative>
                      <Message.Header>Saisie incomplète</Message.Header>
                      <p>
                        Veuillez renseigner le texte de la publication avant de
                        la soumettre
                      </p>
                    </Message>
                  )}
                  <Container
                    fluid
                    textAlign="right"
                    className={
                      this.state.postContentTextLength === 300
                        ? ' red'
                        : this.state.postContentTextLength > 250
                        ? ' orange'
                        : ''
                    }
                  >
                    {this.state.postContentTextLength}/300
                  </Container>
                </Form>
              )}
            </List.Description>

            {item.Lien_x0020_Vid_x00e9_o && (
              <Embed
                url={
                  'https://player.vimeo.com/video/' +
                  item.Lien_x0020_Vid_x00e9_o +
                  '?api=false&amp;autoplay=false&amp;byline=false&amp;color=white&amp;portrait=false&amp;title=false'
                }
                active
                className="video"
              />
            )}
            <Mosaic
              images={item.AttachmentFiles.map(attachment =>
                proxyfy(attachment.ServerRelativeUrl)
              )}
            />
          </List.Content>
        </List.Item>
        <Responsive minWidth={992}>
          <Joyride
            steps={this.state.steps}
            locale={{
              back: 'Retour',
              close: 'Fermer',
              last: 'Dernier',
              next: 'Suivant',
              skip: 'Passer'
            }}
            spotlightClicks={true}
            showSkipButton={true}
            continuous={true}
            showProgress={true}
            run={this.state.isTourOpen}
          />
        </Responsive>
      </Fragment>
    )
  }
}

export default Post
