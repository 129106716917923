//scp -r build/* node@node.alma.fr:/var/www/html/wonderwall/alpha
import React, { Component, Fragment } from "react"

import { Label, Menu } from "semantic-ui-react"
import { Link } from "react-router-dom"
import moment from "moment"
import "moment/locale/fr"
moment.locale("fr")

class DateMenu extends Component {
	constructor() {
		super()
		this.state = {}
	}

	render() {
		const { postsCount } = this.props
		const yearKeys = Object.keys(postsCount).reverse()
		const today = new Date()
		const currYear = today.getFullYear().toString()
		//	const currMonth = today.getMonth().toString()
		const selectedYear = this.props.year
		const selectedMonth = this.props.month
		const handleSidebarHide = () => {
			//console.log("local hanhdle")
			this.props.handleSidebarHide()
		}

		const yearMenuEntries = () => {
			const activeYear = selectedYear //|| currYear
			return yearKeys.map(function(year, i) {
				return (
					<Menu.Item key={i} as={Link} active={year === activeYear ? true : false} to={"/by/" + year}>
						{" "}
						<Label className="bg-pluralis" circular>
							{postsCount[year].count}
						</Label>{" "}
						{year}{" "}
					</Menu.Item>
				)
			})
		}
		const monthMenuEntries = () => {
			let year = selectedYear || currYear
			const yearMonths = postsCount[year]
			const activeMonth = selectedMonth // || currMonth
			if (yearMonths) {
				return Object.entries(yearMonths.items)
					.reverse()
					.map(function(month, i) {
						return (
							<Menu.Item
								key={i}
								as={Link}
								active={month[0] === activeMonth ? true : false}
								to={"/by/" + year + "/" + month[0]}
								onClick={() => handleSidebarHide()}>
								{" "}
								<Label className="bg-pluralis" circular>
									{month[1]}
								</Label>{" "}
								{`${moment()
									.month(month[0])
									.format("MMMM")[0]
									.toUpperCase()}${moment()
									.month(month[0])
									.format("MMMM")
									.slice(1)}`}
							</Menu.Item>
						)
					})
			}
		}

		return (
			<Fragment>
				{(this.props.content === "months" || !this.props.content) && (
					<Fragment>
						{selectedYear && this.props.postsCount && (
							<Menu.Item
								key={"tout"}
								active={!selectedMonth ? true : false}
								as={Link}
								to={"/by/" + selectedYear}
								onClick={() => handleSidebarHide()}>
								{" "}
								<Label className="bg-pluralis" circular>
									{postsCount[selectedYear].count}
								</Label>{" "}
								Tout {selectedYear}
							</Menu.Item>
						)}
						{monthMenuEntries()}
					</Fragment>
				)}
				{(this.props.content === "years" || !this.props.content) && <Fragment>{yearMenuEntries()}</Fragment>}
			</Fragment>
		)
	}
}
export default DateMenu
