import { create } from 'apisauce'
import { sp } from '@pnp/sp'
import moment from 'moment'

import * as OneSignal from 'onesignal-node'

import 'moment/locale/fr'
let serverURL = ''
if (process.env.NODE_ENV === 'production') {
  serverURL = 'https://node.alma.fr/wonderwall-proxy'
} else {
  serverURL = 'http://localhost:8003'
}

const listName = 'Posts'
var SPBaseHost = 'https://pluralishabitatfr.sharepoint.com'
var SPBaseUrl = SPBaseHost + '/sites/walldinfos/'
var SPReturnUrl = SPBaseHost
sp.setup({
  sp: {
    headers: {
      Accept: 'application/json; odata=verbose',
      //"Content-Type": 'application/json'
    },
    //baseUrl: "https://almascop.sharepoint.com/sites/intranet/"
    baseUrl: SPBaseUrl,
  },
})

const api = create({
  baseURL: serverURL,
  //baseURL: 'http://b97fb45c.ngrok.io',
  headers: {
    'Content-Type': 'application/json',
    //'Authorization': localStorage.getItem('RWJToken') ? 'Bearer ' + localStorage.getItem('RWJToken') : null,
  },
})

const client = new OneSignal.Client(
  '8984c0f8-a2f8-48c2-830a-e52e743b9685',
  'ZGFmYTI2ZmEtOTYxOS00NTU5LTk2ZjktM2YxNTJiZTgyM2Zj'
)

const notification = {
  contents: {
    fr: `Nouveau post sur le Wall d'infos!`,
    en: `Nouveau post sur le Wall d'infos!`,
  },
  included_segments: ['All'],
}

export const returnUrl = () => {
  return SPReturnUrl
}
export const isO365 = () => {
  if (window.location.href.indexOf(SPBaseUrl) !== -1) {
    return true
  } else {
    return false
  }
}

export const login = (body) => {
  return api.post('/login', body)
}
export const proxyfy = (url) => {
  if (window.location.href.indexOf(SPBaseUrl) !== -1) {
    //console.log("in sp")
    return SPBaseHost + url
  } else {
    //console.log("in node")
    return serverURL + '/file?&uri=' + url
  }
}
export const getPosts = (date, year, month, service) => {
  const top = 5
  let dateFilter = ''
  let yearFilter = ''
  let monthFilter = ''
  let serviceFilter = ''
  if (date) {
    dateFilter = '&from=' + date
  }
  if (year) {
    yearFilter = '&year=' + year
  }
  if (month) {
    monthFilter = '&month=' + month
  }
  if (service) {
    serviceFilter = '&service=' + service
  }
  let query =
    '/?top=' + top + dateFilter + yearFilter + monthFilter + serviceFilter
  return api.get(query)
}
export const getPostsCount = () => {
  let query = '/count'
  return api.get(query)
}
const getCurrentUser = async () => {
  let user = null
  const userComplete = await sp.web.currentUser.get()
  //console.log(userComplete)
  if (userComplete) {
    user = {
      Id: userComplete.Id,
      LoginName: userComplete.LoginName,
      Title: userComplete.Title,
      UserPrincipalName: userComplete.UserPrincipalName,
    }
  }
  return user
}
const getCurrentUserGroups = async () => {
  let userGroup = []
  const userGroupsComplete = await sp.web.currentUser.groups.get()
  if (userGroupsComplete.length) {
    for (let index = 0; index < userGroupsComplete.length; index++) {
      const element = userGroupsComplete[index]
      userGroup.push({
        Title: element.Title,
      })
    }
  }
  return userGroup
}
const createPost = async (
  service,
  description,
  files,
  videoId,
  services,
  publishNow,
  functionToTrackUpload
) => {
  /*classique*/
  /** */
  /*
	try {
		var fileInfos = []

		if (service !== "") {
			const newItem = await sp.web.lists.getByTitle(listName).items.add({
				Title: services[service],
				Service: service,
				Description: description,
				Publi_x00e9_: publishNow
			})
			for (let index = 0; index < files.length; index++) {
				const file = files[index]
				api.setHeaders({
					"Content-Type": "multipart/form-data"
				})

				fileInfos.push({
					name: file.name.replace(/[^a-zA-Z0-9-_\.]/gi, "_").toLowerCase(),
					content: file
				})
			}

			await newItem.item.attachmentFiles.addMultiple(fileInfos)
		}
	} catch (error) {
		return error
	}
	*/
  /* #region  image_avant_upload_fail */

  /*
	//tentative de retailler les images avant de les attacher par l'upload
	try {
		var fileInfos = [];
	
	if (service!=='')
	{
		const newItem = await sp.web.lists.getByTitle(listName).items.add({
			Title: services[service],
			Service:service,
			Description:description,
			Publi_x00e9_:publishNow
		})
		console.log(newItem)
		const newItemId=newItem.data.Id 
		const formData = new FormData();
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			const formData = new FormData();
			formData.append('file', file);
			api.setHeaders({
				'Content-Type': 'multipart/form-data'
			})
			const img= await	api.post('/resize/',formData )
			console.log(img)
			fileInfos.push({
				name: file.name.replace(/[^a-zA-Z0-9-_\.]/gi, '_').toLowerCase(),
				content: img.data
			});

		}
		console.log(fileInfos)
	

		await newItem.item.attachmentFiles.addMultiple(fileInfos)
}
}
catch (error) 
{
	return (error);
}
*/
  /* #endregion */

  //retaillage ok , c'est le serveur qui fait l'upload
  //--> pas content car permettrait de pourrir les post de façon malveillante

  try {
    if (service !== '') {
      const newItem = await sp.web.lists.getByTitle(listName).items.add({
        Title: services[service],
        Service: service,
        Description: description,
        Lien_x0020_Vid_x00e9_o: videoId,
        Date: moment().toISOString(),
        Publi_x00e9_: publishNow,
      })

      const newItemId = newItem.data.Id
      let upload = null
      if (files.length) {
        for (let index = 0; index < files.length; index++) {
          const formData = new FormData()
          formData.append('file[]', files[index], files[index].name)
          formData.append('Id', newItemId)

          api.setHeaders({
            'Content-Type': 'multipart/form-data',
          })
          functionToTrackUpload(index + 1, files.length)
          upload = await api.post('/upload/', formData)
          if (upload && upload.ok) {
          } else if (upload && !upload.ok) {
            return upload
          }
        }
        if (publishNow) {
          client
            .createNotification(notification)
            .then((response) => {})
            .catch((e) => {})
        }
        return upload
      } else {
        if (publishNow) {
          client
            .createNotification(notification)
            .then((response) => {})
            .catch((e) => {})
        }
        return upload
      }

      //await newItem.item.attachmentFiles.addMultiple(fileInfos)
    }
  } catch (error) {
    return error
  }
  /**/
}
const updatePost = async (itemId, Description) => {
  //const user= await sp.web.currentUser.get()
  try {
    await sp.web.lists.getByTitle(listName).items.getById(itemId).update({
      Description: Description,
    })
    //console.log(updatedItem);
  } catch (error) {
    console.log(error)
    return error
  }
}
const deletePost = async (itemId) => {
  //const user= await sp.web.currentUser.get()
  try {
    await sp.web.lists.getByTitle(listName).items.getById(itemId).delete()
    //console.log(updatedItem);
  } catch (error) {
    console.log(error)
    return error
  }
}
const publishPost = async (itemId) => {
  //const user= await sp.web.currentUser.get()
  try {
    await sp.web.lists.getByTitle(listName).items.getById(itemId).update({
      Publi_x00e9_: true,
    })
    client
      .createNotification(notification)
      .then((response) => {})
      .catch((e) => {})
  } catch (error) {
    console.log(error)
    return error
  }
}

export {
  createPost,
  updatePost,
  publishPost,
  getCurrentUser,
  getCurrentUserGroups,
  deletePost,
}
