//scp -r build/* node@node.alma.fr:/var/www/html/wonderwall/alpha
import React, { Component, Fragment } from "react"
import { Grid, Modal, Icon } from "semantic-ui-react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

class Mosaic extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedImage: 0,
			modalOpen: false
		}
	}
	//handleOpen = (content) => this.setState({ modalContent: content, modalOpen: true })
	handleOpen = (selectedImage) => this.setState({ selectedImage: selectedImage, modalOpen: true })
	handleClose = () => this.setState({ modalOpen: false })
	componentDidMount() {}
	render() {
		const { images } = this.props
		const carouselImages = () => {
			return this.props.images.map(function(image, i) {
				return (
					<div key={image}>
						{" "}
						<img src={image} alt="" />{" "}
					</div>
				)
			})
		}

		return (
			<Fragment>
				{images.length === 1 && (
					<Grid columns={1} className=" mosaic mosaic-1">
						<Grid.Row className="mosaic-row">
							<Grid.Column width={16}>
								<div
									className="cover first"
									onClick={() => this.handleOpen(0)}
									style={{ backgroundImage: "url(" + images[0] + ")" }}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				)}
				{images.length === 2 && (
					<Grid columns={2} className=" mosaic mosaic-2">
						<Grid.Row stretched className="mosaic-row">
							<Grid.Column width={8}>
								<div
									className="cover first"
									onClick={() => this.handleOpen(0)}
									style={{ backgroundImage: "url(" + images[0] + ")" }}
								/>
							</Grid.Column>
							<Grid.Column width={8}>
								<div
									className="cover second"
									onClick={() => this.handleOpen(1)}
									style={{ backgroundImage: "url(" + images[1] + ")" }}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				)}
				{images.length === 3 && (
					<Grid columns={2} className=" mosaic mosaic-3">
						<Grid.Row stretched className="mosaic-row">
							<Grid.Column width={10}>
								<div
									className="cover first"
									onClick={() => this.handleOpen(0)}
									style={{ backgroundImage: "url(" + images[0] + ")" }}
								/>
							</Grid.Column>
							<Grid.Column width={6}>
								<div
									className="cover second"
									onClick={() => this.handleOpen(1)}
									style={{ backgroundImage: "url(" + images[1] + ")" }}
								/>
								<div
									className="cover third"
									onClick={() => this.handleOpen(2)}
									style={{ backgroundImage: "url(" + images[2] + ")" }}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				)}

				{images.length === 4 && (
					<Grid columns={2} className=" mosaic mosaic-4">
						<Grid.Row stretched className="mosaic-row">
							<Grid.Column width={11}>
								<div
									className="cover first"
									onClick={() => this.handleOpen(0)}
									style={{ backgroundImage: "url(" + images[0] + ")" }}
								/>
							</Grid.Column>
							<Grid.Column width={5}>
								<div
									className="cover second"
									onClick={() => this.handleOpen(1)}
									style={{ backgroundImage: "url(" + images[1] + ")" }}
								/>
								<div
									className="cover third"
									onClick={() => this.handleOpen(2)}
									style={{ backgroundImage: "url(" + images[2] + ")" }}
								/>
								<div
									className="cover fourth"
									onClick={() => this.handleOpen(3)}
									style={{ backgroundImage: "url(" + images[3] + ")" }}
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				)}

				<Modal basic size={"small"} open={this.state.modalOpen} onClose={this.handleClose}>
					<Icon circular inverted name="close" className="mosaic-close" onClick={this.handleClose} />
					<style>{`
					@media only screen and (max-width: 767px){

						.ui.basic.modal>.close {
							top: -0.75rem!important;
							right: -0.5rem!important;
						}
					}
					.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
						opacity: 1;
					}
					.carousel .control-next.control-arrow:before {
						border-left: 24px solid #fff;
					}

					.carousel .control-prev.control-arrow:before {
						border-right: 24px solid #fff;
					}
					.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
						border-top: 24px solid transparent;
						border-bottom: 24px solid transparent;
					}
					`}</style>
					<Modal.Content>
						<Carousel
							showArrows={true}
							showThumbs={false}
							dynamicHeight={true}
							selectedItem={this.state.selectedImage}
							showStatus={false}>
							{carouselImages()}
						</Carousel>
					</Modal.Content>
				</Modal>
			</Fragment>
		)
	}
}

export default Mosaic
