//scp -r build/* node@node.alma.fr:/var/www/html/wonderwall/alpha
import React, { Component, Fragment } from "react"
import { Image, Menu, Button } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { TinyButton as ScrollUpButton } from "react-scroll-up-button"

class ServiceMenu extends Component {
	constructor() {
		super()
		this.state = {
			mobileMenuVisible: false,
			modalOpen: false,
			modalContent: false,
			isShowingMore: false,
			isLoading: true,
			isTourOpen: false,
			postServiceFilter: "",
			year: "",
			month: ""
		}
	}

	handleServiceClick = (service) => {}

	componentDidMount() {
		//this.getData()
		//console.log("did mount")
	}
	render() {
		const { service } = this.props.match.params
		const {
			com_active,
			com,
			dev_active,
			dev,
			pat_active,
			pat,
			hab_active,
			hab,
			rh_active,
			rh,
			sup_active,
			sup,
			isalis_active,
			isalis,
			dir_active,
			dir
		} = this.props.serviceLogos
		return (
			<Fragment>
				<Menu borderless compact fixed={this.props.isMobile === true ? null : null} className={"menu-services"}>
					<Menu.Item
						as={Link}
						to={service !== "com" ? "/s/com" : "/"}
						active={service === "com"}
						onClick={() => this.handleServiceClick("com")}>
						<Image avatar src={service === "com" ? com_active : com} size="mini" />
					</Menu.Item>
					<Menu.Item
						as={Link}
						to={service !== "dev" ? "/s/dev" : "/"}
						active={service === "dev"}
						onClick={() => this.handleServiceClick("dev")}>
						<Image avatar src={service === "dev" ? dev_active : dev} size="mini" />
					</Menu.Item>
					<Menu.Item
						as={Link}
						to={service !== "pat" ? "/s/pat" : "/"}
						active={service === "pat"}
						onClick={() => this.handleServiceClick("pat")}>
						<Image avatar src={service === "pat" ? pat_active : pat} size="mini" />
					</Menu.Item>
					<Menu.Item
						as={Link}
						to={service !== "hab" ? "/s/hab" : "/"}
						active={service === "hab"}
						onClick={() => this.handleServiceClick("hab")}>
						<Image avatar src={service === "hab" ? hab_active : hab} size="mini" />
					</Menu.Item>
					<Menu.Item
						as={Link}
						to={service !== "rh" ? "/s/rh" : "/"}
						active={service === "rh"}
						onClick={() => this.handleServiceClick("rh")}>
						<Image avatar src={service === "rh" ? rh_active : rh} size="mini" />
					</Menu.Item>
					<Menu.Item
						as={Link}
						to={service !== "sup" ? "/s/sup" : "/"}
						active={service === "sup"}
						onClick={() => this.handleServiceClick("sup")}>
						<Image avatar src={service === "sup" ? sup_active : sup} size="mini" />
					</Menu.Item>
					<Menu.Item
						as={Link}
						to={service !== "isalis" ? "/s/isalis" : "/"}
						active={service === "isalis"}
						onClick={() => this.handleServiceClick("isalis")}>
						<Image avatar src={service === "isalis" ? isalis_active : isalis} size="mini" />
					</Menu.Item>
					<Menu.Item
						as={Link}
						to={service !== "dir" ? "/s/dir" : "/"}
						active={service === "dir"}
						onClick={() => this.handleServiceClick("dir")}>
						<Image avatar src={service === "dir" ? dir_active : dir} size="mini" />
					</Menu.Item>
					{this.props.isMobile && (
						<Menu.Item className="burger">
							<Button basic circular icon="bars" size="mini" onClick={() => this.props.handleShowClick()} />
						</Menu.Item>
					)}
					<ScrollUpButton />
					{/**
						
						<Menu.Item onClick={window.scrollTo(0,0)} className="back-top">
								<Button basic icon='chevron up' size='mini'   />
							</Menu.Item>
						 */}
				</Menu>
			</Fragment>
		)
	}
}

export default ServiceMenu
