//scp -r build/* node@node.alma.fr:/var/www/html/wonderwall/alpha
import React, { Component, Fragment } from 'react'
import './App.css'
import {
  Grid,
  Image,
  Container,
  Header,
  Icon,
  Form,
  Segment,
  Button,
  Message,
  Dropdown,
} from 'semantic-ui-react'
import UserAvatar from 'react-user-avatar'
import { HashRouter as Router, Route, Link } from 'react-router-dom'

import moment from 'moment'

import {
  getCurrentUser,
  getCurrentUserGroups,
  login,
  isO365,
  returnUrl,
} from './Services/Api'
import Main from './Views/Main'
import ServiceMenu from './Components/ServiceMenu'
import com from './img/com.png'
import dev from './img/dev.png'
import dir from './img/dir.png'
import hab from './img/hab.png'
import isa from './img/isalis.png'
import pat from './img/pat.png'
import rh from './img/rh.png'
import sup from './img/sup.png'
import logoMobileLogin from './img/logo-mobile-login.png'
import logoPluralis from './img/logo-pluralis.jpg'
import com_active from './img/com_.png'
import dev_active from './img/dev_.png'
import dir_active from './img/dir_.png'
import hab_active from './img/hab_.png'
import isa_active from './img/isalis_.png'
import pat_active from './img/pat_.png'
import rh_active from './img/rh_.png'
import sup_active from './img/sup_.png'

import 'moment/locale/fr'
import ReactGA from 'react-ga'

ReactGA.initialize('UA-141831562-1')
ReactGA.pageview(window.location.pathname + window.location.search)
moment.locale('fr')

const serviceLogos = {
  com: com,
  dev: dev,
  pat: pat,
  hab: hab,
  rh: rh,
  sup: sup,
  isalis: isa,
  dir: dir,
  com_active: com_active,
  dev_active: dev_active,
  pat_active: pat_active,
  hab_active: hab_active,
  rh_active: rh_active,
  sup_active: sup_active,
  isalis_active: isa_active,
  dir_active: dir_active,
}

const serviceNames = {
  com: 'Service Communication',
  dev: 'Service Développement',
  pat: 'Service Patrimoine',
  hab: 'Service aux Habitants',
  rh: 'Service Ressources Humaines',
  sup: 'Services Support',
  isalis: 'Isalis',
  dir: 'Direction Générale',
}

class App extends Component {
  constructor() {
    super()
    this.state = {
      mobileMenuVisible: false,
      modalOpen: false,
      modalContent: false,
      isShowingMore: false,
      isLoading: true,
      isTourOpen: false,
      postServiceFilter: '',
      year: '',
      month: '',
      user: null,
      userGroups: [],
      email: '',
      password: '',
      isLoggedIn: null,
      loginError: false,
    }
  }
  async componentDidMount() {
    var user = null
    var userGroups = []

    if (isO365()) {
      //	console.log("in o365")
      user = await getCurrentUser()
      userGroups = await getCurrentUserGroups()
    } else {
      user = JSON.parse(localStorage.getItem('WDIUser'))
    }

    if (user) {
      //console.log(user)
      //console.log(userGroups)
      this.setState({ user: user, userGroups: userGroups, isLoggedIn: true })
    } else {
      this.setState({ isLoggedIn: false })
    }
  }
  handleHideClick = () => this.setState({ mobileMenuVisible: false })
  handleShowClick = () => {
    this.setState({ mobileMenuVisible: true })
  }
  handleSidebarHide = () => this.setState({ mobileMenuVisible: false })
  isLoggedIn = () => {
    return false
  }
  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
    //	console.log(this.state)
  }
  async call_login() {
    const { email, password } = this.state
    const response = await login({ email, password })
    if (response.ok) {
      const user = response.data.d
      const { Id, LoginName, Title, UserPrincipalName } = user
      const userData = { Id, LoginName, Title, UserPrincipalName }
      localStorage.setItem('WDIUser', JSON.stringify(userData))

      this.setState({ user: userData, isLoggedIn: true, loginError: false })
    } else {
      this.setState({ loginError: true, isLoggedIn: false })
    }
  }
  resetLoginError() {
    this.setState({ loginError: false })
  }
  handleUserMenuChange = (e, { value }) => {
    console.log('close')
    if (value === 'close') {
      window.opener ? window.close() : (window.location = returnUrl())
    } else {
      this.logout()
    }
  }
  logout = () => {
    localStorage.removeItem('WDIUser')
    this.setState({ user: null, isLoggedIn: false, mobileMenuVisible: false })
  }
  render() {
    const { isLoggedIn } = this.state
    const logoutOptions = isO365()
      ? [
          {
            key: 'close-window',
            value: 'close',
            text: 'Revenir au Portail',
            icon: 'sign out',
            selected: false,
            className: 'delimiter-top',
          },
        ]
      : [
          {
            key: 'sign-out',
            value: 'signout',
            text: 'Déconnexion',
            icon: 'sign out',
            selected: false,
            className: 'delimiter-top',
          },
        ]
    return (
      <Router>
        {isLoggedIn === true && (
          <div className="App" id="app">
            <Container
              fluid
              className="App-header bg-white "
              style={{ marginTop: '14px' }}
            >
              <Grid container>
                <Grid.Row className="bg-white " only="computer tablet">
                  <Grid.Column only="computer" computer={4}>
                    <div className="App-logo">
                      <Image src={logoPluralis} size="mini" circular />
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    computer={9}
                    tablet={12}
                    textAlign="center"
                    className="menu-container"
                  >
                    <Route
                      path={['/', '/by/:year?/:month?', '/s/:service?']}
                      exact
                      render={(props) => (
                        <ServiceMenu
                          match={props.match}
                          serviceLogos={serviceLogos}
                        />
                      )}
                    />
                  </Grid.Column>
                  <Grid.Column computer={3} tablet={4} className="header-extra">
                    <style>{`
											.UserAvatar {
												display:inline-block;
												margin-right:-10px;
											}
											.UserAvatar--inner{
												border:1px solid dimgrey;
											}
											.menu-user .header{text-transform:none!important;
												color: dimgrey;
												text-align: center;
												margin-top:0.5rem !important;
												margin-bottom:0.5rem !important;
											}
											.menu-user .delimiter-top{
												border-top:1px solid #d4d4d5!important;
											}
										`}</style>
                    <Dropdown
                      onChange={this.handleUserMenuChange}
                      className="menu-user"
                      trigger={
                        <UserAvatar
                          size="35"
                          name={this.state.user ? this.state.user.Title : ''}
                          color="#fff"
                        />
                      }
                      header={this.state.user.Title}
                      options={logoutOptions}
                      selectOnBlur={false}
                      pointing="top right"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="bg-white " only="mobile">
                  <Grid.Column
                    mobile={16}
                    textAlign="center"
                    className="menu-container"
                  >
                    <Route
                      path={['/', '/by/:year?/:month?', '/s/:service?']}
                      exact
                      render={(props) => (
                        <ServiceMenu
                          match={props.match}
                          serviceLogos={serviceLogos}
                          isMobile={true}
                          handleShowClick={() => this.handleShowClick()}
                        />
                      )}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>

            <Route
              path={['/s/:service?']}
              exact
              render={(props) => {
                return (
                  props.match.params.service && (
                    <Fragment>
                      <Container
                        fluid
                        className={
                          ' App-header mobile-secondary-menu bg-' +
                          props.match.params.service
                        }
                      >
                        <Grid container>
                          <Grid.Row only="mobile" textAlign="center">
                            <Link
                              to="/"
                              onClick={() => this.handleSidebarHide()}
                            >
                              <Icon
                                name="angle left"
                                size="large"
                                style={{ float: 'left', paddingLeft: '15px' }}
                              />
                              <Header size="tiny" style={{ display: 'inline' }}>
                                {serviceNames[props.match.params.service]}
                              </Header>
                            </Link>
                          </Grid.Row>
                        </Grid>
                      </Container>
                    </Fragment>
                  )
                )
              }}
            />
            <Route
              path={`/by/:year?/:month?`}
              exact
              render={(props) => {
                return (
                  props.match.params.year && (
                    <Fragment>
                      <Container
                        fluid
                        className={
                          ' App-header mobile-secondary-menu bg-pluralis'
                        }
                      >
                        <Grid container>
                          <Grid.Row only="mobile" textAlign="center">
                            <Link
                              to="/"
                              onClick={() => this.handleSidebarHide()}
                            >
                              <Icon
                                name="angle left"
                                size="large"
                                style={{ float: 'left', paddingLeft: '15px' }}
                              />
                              <Header size="tiny" style={{ display: 'inline' }}>
                                {props.match.params.month && (
                                  <span style={{ marginRight: '10px' }}>
                                    {`${moment()
                                      .month(props.match.params.month)
                                      .format('MMMM')[0]
                                      .toUpperCase()}${moment()
                                      .month(props.match.params.month)
                                      .format('MMMM')
                                      .slice(1)}`}
                                  </span>
                                )}
                                <span>{props.match.params.year} </span>
                              </Header>
                            </Link>
                          </Grid.Row>
                        </Grid>
                      </Container>
                    </Fragment>
                  )
                )
              }}
            />

            <Route
              path={`/by/:year?/:month?`}
              exact
              render={(props) => (
                <Main
                  {...props}
                  /*service={this.state.postServiceFilter} */
                  serviceLogos={serviceLogos}
                  handleSidebarHide={this.handleSidebarHide}
                  mobileMenuVisible={this.state.mobileMenuVisible}
                  serviceNames={serviceNames}
                  user={this.state.user}
                  userGroups={this.state.userGroups}
                  logout={this.logout}
                />
              )}
            />
            <Route
              path={`/s/:service?`}
              exact
              render={(props) => (
                <Main
                  {...props}
                  /*service={this.state.postServiceFilter} */
                  serviceLogos={serviceLogos}
                  handleSidebarHide={this.handleSidebarHide}
                  mobileMenuVisible={this.state.mobileMenuVisible}
                  serviceNames={serviceNames}
                  user={this.state.user}
                  userGroups={this.state.userGroups}
                  logout={this.logout}
                />
              )}
            />

            <Route
              path={`/`}
              exact
              render={(props) => (
                <Main
                  {...props}
                  /*	service={this.state.postServiceFilter} */
                  serviceLogos={serviceLogos}
                  handleSidebarHide={this.handleSidebarHide}
                  mobileMenuVisible={this.state.mobileMenuVisible}
                  handleServiceClick={this.handleServiceClick}
                  serviceNames={serviceNames}
                  user={this.state.user}
                  userGroups={this.state.userGroups}
                  logout={this.logout}
                />
              )}
            />
          </div>
        )}
        {isLoggedIn === false && (
          <div className="login-form " id="app">
            {/*
						Heads up! The styles below are necessary for the correct render of this example.
						You can do same with CSS, the main idea is that all the elements up to the `Grid`
						below must have a height of 100%.
					*/}
            <style>
              {`
						body > div,
						body > div > div,
						body > div > div > div.login-form {
							height: 100%;
						}
					`}
            </style>
            <Grid
              textAlign="center"
              style={{ height: '100%' }}
              verticalAlign="middle"
            >
              <Grid.Column style={{ maxWidth: 450, backgroundColor: '#fff' }}>
                <Image src={logoMobileLogin} size="larger" />
                <Form size="large">
                  <Segment>
                    <Form.Input
                      fluid
                      icon="user"
                      iconPosition="left"
                      placeholder="nom@pluralis-habitat.fr"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleInputChange.bind(this)}
                      onFocus={this.resetLoginError.bind(this)}
                    />
                    <Form.Input
                      fluid
                      icon="lock"
                      iconPosition="left"
                      placeholder="Mot de Passe - idem connexion Office 365 "
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleInputChange.bind(this)}
                      onFocus={this.resetLoginError.bind(this)}
                    />
                    {this.state.loginError && (
                      <Message negative>
                        <Message.Header>
                          Informations de connexion erronnées
                        </Message.Header>
                        <p>Veuillez réessayer.</p>
                      </Message>
                    )}
                    <Button
                      className="bg-pluralis"
                      style={{ color: '#fff' }}
                      fluid
                      size="large"
                      onClick={this.call_login.bind(this)}
                    >
                      Connexion
                    </Button>
                  </Segment>
                </Form>
                {/* 
								<Message>
									New to us? <a href='#'>Sign Up</a>
								</Message>
							*/}
              </Grid.Column>
            </Grid>
          </div>
        )}
      </Router>
    )
  }
}

export default App
