//scp -r build/* node@node.alma.fr:/var/www/html/wonderwall/alpha
import React, { Component, createRef, Fragment } from "react"
import {
	Image,
	Container,
	Button,
	Modal,
	Header,
	Icon,
	Form,
	Input,
	Popup,
	Dropdown,
	Dimmer,
	Loader,
	Segment,
	Placeholder,
	Message
} from "semantic-ui-react"
import { getCurrentUserGroups } from "../Services/Api"
import Dropzone from "react-dropzone"
import com from "../img/com.png"
import dev from "../img/dev.png"
import dir from "../img/dir.png"
import hab from "../img/hab.png"
import isa from "../img/isalis.png"
import pat from "../img/pat.png"
import rh from "../img/rh.png"
import sup from "../img/sup.png"

import { createPost } from "../Services/Api"

class PostEdit extends Component {
	contextRef = createRef()
	constructor(props) {
		super(props)

		this.state = {
			files: [],
			postContentText: "",
			postContentTextLength: 0,
			postContentTextLengthMax: 300,
			modalOpen: false,
			isLoading: false,
			selectedService: null,
			userService: null,
			userIsCom: null,
			showError: false,
			errorMessage: {
				title: "Saisie incomplète",
				msg: "Veuillez renseigner le texte de la publication avant de la soumettre"
			},
			videoId: "",
			currentlyProcessedImage: false,
			totalImageToProces: false
		}
	}

	handleOpen = () => this.setState({ modalOpen: true })
	handleClose = () => this.setState({ modalOpen: false })
	loadingShow = () => this.setState({ isLoading: true })
	loadingHide = () => this.setState({ isLoading: false })
	handleServiceChange = (e, option) => {
		const { value } = option
		let selectedOption = option.options.find((o) => o.value === value)

		this.setState({ selectedService: selectedOption })
	}
	serviceOptions = [
		{
			key: this.props.serviceNames["com"],
			text: "",
			value: "com",
			className: "color-com",
			image: { avatar: true, src: com }
		},
		{
			key: this.props.serviceNames["dev"],
			text: "",
			value: "dev",
			className: "color-dev",
			image: { avatar: true, src: dev }
		},
		{
			key: this.props.serviceNames["pat"],
			text: "",
			value: "pat",
			className: "color-pat",
			image: { avatar: true, src: pat }
		},
		{
			key: this.props.serviceNames["hab"],
			text: "",
			value: "hab",
			className: "color-hab",
			image: { avatar: true, src: hab }
		},
		{
			key: this.props.serviceNames["rh"],
			text: "",
			value: "rh",
			className: "color-rh",
			image: { avatar: true, src: rh }
		},
		{
			key: this.props.serviceNames["sup"],
			text: "",
			value: "sup",
			className: "color-sup",
			image: { avatar: true, src: sup }
		},
		{
			key: this.props.serviceNames["isalis"],
			text: "",
			value: "isalis",
			className: "color-isa",
			image: { avatar: true, src: isa }
		},
		{
			key: this.props.serviceNames["dir"],
			text: "",
			value: "dir",
			className: "color-dir",
			image: { avatar: true, src: dir }
		}
	]

	onDragEnter = () => {
		//console.log("entering drag")
	}
	onPreviewDrop = (acceptedFiles) => {
		acceptedFiles.map((file, index) => {
			return Object.assign(file, {
				preview: URL.createObjectURL(file)
			})
		})
		var fourOnly = this.state.files.concat(acceptedFiles)
		fourOnly = fourOnly.filter((file, index) => {
			return index < 4
		})

		this.setState({
			files: fourOnly
		})
	}

	deleteFile = (fileName) => {
		var initFiles = this.state.files
		var newFiles = initFiles.filter((file) => {
			return file.name !== fileName
		})

		this.setState({
			showError: false,
			files: newFiles
		})
	}
	onFieldPostContentTextChange = (e) => {
		var textContent = e.target.value
		var textContentLength = textContent.length
		if (textContentLength > this.state.postContentTextLengthMax) {
			return
		}
		this.setState({ postContentText: e.target.value, postContentTextLength: textContentLength, showError: false })
	}
	onFieldVideoChange = (e) => {
		var text = e.target.value
		if (!text.length) {
			return
		}
		this.setState({ videoId: e.target.value })
	}

	call_createPost(publishNow) {
		this.setState({ currentlyProcessedImage: null, totalImageToProces: null, processingImageDone: null })
		if (this.state.postContentText) {
			this.loadingShow()
			createPost(
				this.state.selectedService.value,
				this.state.postContentText,
				this.state.files,
				this.state.videoId,

				this.props.serviceNames,
				publishNow,
				this.updateCurrentlyProcessedImage
			).then((result) => {
				//	console.log(result)
				if (result && !result.ok) {
					this.loadingHide()
					this.setState({
						errorMessage: {
							title: "Echec du téléchargement ",
							msg: "La taille totale des photos téléchargées doit etre inférieure à 11M"
						},
						showError: true
					})
				} else {
					this.handleClose()
					this.loadingHide()
					this.props.refresh()
					this.setState({ postContentText: "", files: [], postContentTextLength: 0 })
				}
			})
		} else {
			this.setState({ showError: true })
		}
	}
	async componentDidMount() {
		try {
			let userGroups = await getCurrentUserGroups()
			//this.setState({user:user,userGroups:userGroups})
			if (userGroups.length !== 0 && !this.state.selectedService) {
				const { Title } = userGroups[0]
				let selectedOption = this.serviceOptions.find((o) => o.value === Title)
				const userIsCom = userGroups.find((o) => o.Title === "com") ? true : false
				this.setState({ selectedService: selectedOption, userService: Title, userIsCom })
			}
		} catch (error) {
			return error
		}
	}
	updateCurrentlyProcessedImage = (num, total, done) => {
		this.setState({ currentlyProcessedImage: num, totalImageToProces: total })
	}

	render() {
		//	console.log(this.props.userGroups)
		const thumbs = this.state.files.map((file) => (
			<Icon.Group size="large" className="new-image" key={file.name}>
				<Image src={file.preview} size="small" />
				<Popup
					trigger={
						<Icon corner="top right" circular inverted name="close" onClick={() => this.deleteFile(file.name)} />
					}
					content="Supprimer l'image"
				/>
			</Icon.Group>
		))
		return (
			<Modal
				trigger={<Button circular basic icon="plus" size="large" className="add-post" onClick={this.handleOpen} />}
				open={this.state.modalOpen}
				onClose={this.handleClose}
				closeOnDimmerClick={true}
				className="new-post"
				size="small">
				<Icon circular inverted name="close" className="edit-close" onClick={this.handleClose} />
				{!this.state.isLoading && (
					<Fragment>
						<style>{`
							.new-image{
								margin-right:5px;
							}
							.char-count
							{
								font-size:12px;
								margin-top:-10px;
							}
							
					`}</style>
						<Header className={this.state.selectedService ? this.state.selectedService.className : ""}>
							{this.state.userIsCom && (
								<Dropdown
									className="service-selector"
									placeholder=""
									inline
									options={this.serviceOptions}
									onChange={this.handleServiceChange}
									value={this.state.service ? this.state.service.value : ""}
								/>
							)}
							<Image
								circular
								src={this.state.selectedService ? this.state.selectedService.image.src : ""}
								style={{ marginTop: "-5px" }}
							/>{" "}
							<span style={{ paddingTop: "5px", marginLeft: "5px" }}>
								{this.state.selectedService ? this.state.selectedService.key : ""}
							</span>
						</Header>
						<Modal.Content>
							<Form>
								<Form.TextArea
									placeholder="Quoi de neuf ?"
									onChange={this.onFieldPostContentTextChange}
									value={this.state.postContentText}
								/>
								{this.state.userIsCom && (
									<Input
										icon="vimeo square"
										onChange={this.onFieldVideoChange}
										value={this.state.videoId}
										placeholder="Identifiant video"
									/>
								)}
								<Container
									fluid
									textAlign="right"
									className={
										this.state.postContentTextLength === 300
											? " red"
											: this.state.postContentTextLength > 250
											? " orange"
											: ""
									}>
									{this.state.postContentTextLength}/300
								</Container>
								{this.state.showError && (
									<Message negative>
										<Message.Header>{this.state.errorMessage.title}</Message.Header>
										<p>{this.state.errorMessage.msg}</p>
									</Message>
								)}
								{this.state.files.length > 0 && <Fragment>{thumbs}</Fragment>}
								{this.state.files.length > 0 && this.state.files.length < 4 && (
									<Icon.Group size="large" className="new-image">
										{/*https://react-dropzone.js.org/*/}
										<Dropzone onDrop={this.onPreviewDrop} onDragOver={this.onDragOver} accept="image/*">
											{({ getRootProps, getInputProps }) => (
												<section className="container">
													<div {...getRootProps({ className: "dropzone" })}>
														<input {...getInputProps()} />
														<Popup
															trigger={
																<Button
																	circular
																	basic
																	icon="add"
																	size="mini"
																	className="add-image"
																	style={{ marginLeft: "15px" }}
																/>
															}
															content="Ajouter une image"
														/>
													</div>
												</section>
											)}
										</Dropzone>
									</Icon.Group>
								)}
							</Form>
							{/*
							<Container style={{ marginBottom: "-15px", marginTop: "5px", fontStyle: "italic", fontSize: "0.75em" }}>
								*Il se peut que les photos d'Iphone soient mal orientées, elles seront corrigées lors de
								l'enregistrement de la publication
							</Container>
							 */}
						</Modal.Content>
						<Modal.Actions>
							<Container textAlign="right" className="toolbar">
								{this.state.files.length < 4 && (
									<Dropzone onDrop={this.onPreviewDrop} accept="image/*">
										{({ getRootProps, getInputProps }) => (
											<section className="container">
												<div {...getRootProps({ className: "dropzone" })}>
													<input {...getInputProps()} />
													<Popup
														trigger={
															<Button circular basic icon="image" size="big" className="add-image" floated="left" />
														}
														content="Ajouter une image"
													/>
												</div>
											</section>
										)}
									</Dropzone>
								)}

								<Popup
									trigger={
										<Button
											circular
											basic
											icon="clipboard check"
											size="big"
											className="check"
											onClick={() => this.call_createPost(false)}
										/>
									}
									content="Vérifier avant publication"
								/>
								<Popup
									trigger={
										<Button
											circular
											basic
											icon="check"
											size="big"
											className="check"
											onClick={() => this.call_createPost(true)}
										/>
									}
									content="Publier immédiatement cette publication"
								/>
							</Container>
						</Modal.Actions>
					</Fragment>
				)}
				{this.state.isLoading && (
					<Dimmer.Dimmable as={Segment} dimmed={this.state.isLoading}>
						<Dimmer active={this.state.isLoading}>
							<Loader>
								<Fragment>
									Redimensionnement de l'image {this.state.currentlyProcessedImage} sur {this.state.totalImageToProces}{" "}
									...
								</Fragment>
							</Loader>
						</Dimmer>
						<Placeholder fluid inverted>
							<Placeholder.Line length="full" />
							<Placeholder.Line length="full" />
							<Placeholder.Line length="full" />
							<Placeholder.Line length="full" />
						</Placeholder>
					</Dimmer.Dimmable>
				)}
			</Modal>
		)
	}
}

export default PostEdit
