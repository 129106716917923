//scp -r build/* node@node.alma.fr:/var/www/html/wonderwall/alpha
import React, { Component, createRef, Fragment } from 'react'
import {
  Grid,
  List,
  Image,
  Container,
  Dimmer,
  Menu,
  Ref,
  Sticky,
  Card,
  Responsive,
  Placeholder,
  Label,
  Header,
  Sidebar,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import _ from 'lodash'
import { getPosts, getPostsCount } from '../Services/Api'
import DateMenu from '../Components/DateMenu'
import PostEdit from '../Components/PostEdit'
import Post from '../Components/Post'
import InfiniteScroll from 'react-infinite-scroller'
import logo from '../img/logo.jpg'
import logoMobile from '../img/logo-mobile.jpg'
import logoPluralis from '../img/logo-pluralis.jpg'

class Main extends Component {
  contextRef = createRef()

  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      modalContent: false,
      isShowingMore: false,
      isLoading: true,
      posts: [],
      hasMore: true,
      postDateFilter: '',
      postMonthFilter: '',
      postsCount: false,
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
    }
    if (this.props.match.params.service !== prevProps.match.params.service) {
      this.setState({ posts: [], hasMore: true, postDateFilter: '' })
    }
    if (this.props.match.params.year !== prevProps.match.params.year) {
      this.setState({ posts: [], hasMore: true, postDateFilter: '' })
    }
    if (this.props.match.params.month !== prevProps.match.params.month) {
      this.setState({ posts: [], hasMore: true, postDateFilter: '' })
    }
  }
  refresh() {
    this.setState({ posts: [], hasMore: true, postDateFilter: '' })
  }
  removePost(id) {
    const newPosts = this.state.posts
    _.remove(newPosts, function (el) {
      // remove all numbers
      return el.Id === id
    })
    this.setState({ posts: newPosts })
  }

  async getData() {
    var response
    var top = 4
    //alert("test has more" +!this.state.hasMore )
    if (!this.state.hasMore) {
      return false
    }
    var postsCount = this.state.postsCount

    if (!postsCount) {
      response = await getPostsCount()
      if (response.ok) {
        postsCount = response.data
        // console.log(postsCount)
        //this.setState({postsCount:postsCount})
      } else {
        console.log('error ' + response)
        this.setState({
          hasMore: false,
          isLoading: false,
          hasError: true,
          error: response,
        })
        return
      }
    }

    response = await getPosts(
      this.state.postDateFilter,
      this.props.match.params.year,
      this.props.match.params.month,
      this.props.match.params.service
    )
    if (response.ok) {
      /*this.props.handleSidebarHide()*/
      let posts = response.data

      //pour éviter les doublons on début de vue de l'appli
      if (
        this.state.posts.length &&
        posts.length &&
        this.state.posts[0].Id === posts[0].Id
      ) {
        return
      }

      let dateFilter = this.state.postDateFilter
      if (posts.length) {
        var lastPostReturned = posts[posts.length - 1]
        dateFilter = lastPostReturned.Date
      }
      let hasMore = posts.length < top ? false : this.state.hasMore
      this.setState({
        posts: [...this.state.posts, ...posts],
        hasError: false,
        isLoading: false,
        postDateFilter: dateFilter,
        hasMore: hasMore,
        postsCount: postsCount,
      })
    } else {
      // Error
      console.log('error ' + response)
      this.setState({
        hasMore: false,
        isLoading: false,
        hasError: true,
        error: response,
      })
    }
  }

  render() {
    const serviceLogos = this.props.serviceLogos
    const { user, userGroups } = this.props
    const removePost = (id) => {
      this.removePost(id)
    }
    const posts = () => {
      const posts = this.state.posts
      return posts.map(function (listItem, i) {
        return (
          <Post
            key={listItem.Id}
            item={listItem}
            serviceLogos={serviceLogos}
            user={user}
            userGroups={userGroups}
            removePost={() => removePost(listItem.Id)}
          />
        )
      })
    }

    const isInService = () => {
      let result = false
      userGroups.forEach((element) => {
        if (Object.keys(this.props.serviceNames).includes(element.Title)) {
          result = true
        }
      })
      return result
    }

    //this.props.handleSidebarHide()
    return (
      <Fragment>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          direction="top"
          style={{ zIndex: 10001 }}
          className={'mobile-menu'}
          onHide={this.props.handleSidebarHide}
          vertical
          visible={this.props.mobileMenuVisible}
          width="thin"
        >
          <Menu.Item
            as={Link}
            to="/"
            onClick={() => this.props.handleSidebarHide()}
          >
            <style>{`
								.ui.label>.icon.home{
								margin-right:-5px;
								font-size: 1.5em;
								margin-top:-2px;
								}
							`}</style>
            <Label
              className="color-pluralis"
              circular
              icon="home"
              style={{ backgroundColor: 'transparent' }}
            />
            ACCUEIL
          </Menu.Item>
          <DateMenu
            postsCount={this.state.postsCount}
            year={this.props.match.params.year}
            month={this.props.match.params.month}
            handleSidebarHide={this.props.handleSidebarHide}
          />
          <Menu.Item
            as={Link}
            to="/"
            onClick={() => this.props.logout()}
            style={{ borderTop: '2px solid #648BB6' }}
          >
            <style>{`
								.ui.label>.icon.sign.out{
								margin-right:-5px;
								font-size: 1.5em;
								margin-top:-2px;
								}
							`}</style>
            <Label
              className="color-pluralis"
              circular
              icon="sign out"
              style={{ backgroundColor: 'transparent' }}
            />
            Déconnexion ({this.props.user.Title})
          </Menu.Item>
        </Sidebar>
        <Grid container className={'main-layout'}>
          <Grid.Row>
            <Grid.Column only="computer" width={4} className="left-column">
              <Sticky context={this.contextRef} pushing>
                <Card>
                  <Link to="/">
                    <Image src={logo} />
                  </Link>

                  <Card.Content>
                    {/*<Card.Header>WonderWall</Card.Header>*/}
                    <Card.Meta>
                      Tenez vous informés des infos de tous les services
                    </Card.Meta>
                    <Card.Description />
                  </Card.Content>
                </Card>
              </Sticky>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={12}
              computer={9}
              textAlign="left"
              className="middle-column"
            >
              <Responsive as={Container} textAlign="center" minWidth={992}>
                {isInService() && (
                  <PostEdit
                    serviceNames={this.props.serviceNames}
                    refresh={this.refresh.bind(this)}
                    userGroups={userGroups}
                  />
                )}
              </Responsive>
              <Ref innerRef={this.contextRef}>
                <List divided relaxed className="bg-white  center-content">
                  <Dimmer active={this.props.mobileMenuVisible} />
                  <Responsive maxWidth={992}>
                    {!this.props.match.params.service && (
                      <List.Item>
                        <Card fluid className="mobile-card-logo">
                          <Link to="/">
                            <Image src={logoMobile} />
                          </Link>
                          <Container
                            fluid
                            textAlign="center"
                            style={{ marginBottom: '10px' }}
                          >
                            <Image
                              className="pluralis-logo"
                              src={logoPluralis}
                              size="tiny"
                              circular
                            />
                          </Container>
                        </Card>
                      </List.Item>
                    )}
                  </Responsive>
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={() => this.getData()}
                    hasMore={this.state.hasMore}
                    loader={
                      <div className="loader" key={0}>
                        <style>{`
											.no-items{
												display:none!important;
											}
										`}</style>
                        {_.times(1, String).map(function (item, i) {
                          return (
                            <List.Item key={i}>
                              <Placeholder fluid>
                                <Placeholder.Header image>
                                  <Placeholder.Line length="full" />
                                  <Placeholder.Line />
                                </Placeholder.Header>
                              </Placeholder>
                              <Placeholder
                                style={{
                                  marginLeft: 50,
                                  height: 150,
                                  width: '85%',
                                }}
                              >
                                <Placeholder.Image />
                              </Placeholder>
                            </List.Item>
                          )
                        })}
                      </div>
                    }
                  >
                    {posts()}
                  </InfiniteScroll>
                  {this.state.isLoading &&
                    _.times(5, String).map(function (item, i) {
                      return (
                        <List.Item key={i}>
                          <Placeholder fluid>
                            <Placeholder.Header image>
                              <Placeholder.Line length="full" />
                              <Placeholder.Line />
                            </Placeholder.Header>
                          </Placeholder>
                          <Placeholder
                            style={{
                              marginLeft: 50,
                              height: 150,
                              width: '85%',
                            }}
                          >
                            <Placeholder.Image />
                          </Placeholder>
                        </List.Item>
                      )
                    })}
                  {!this.state.isLoading && !this.state.posts.length && (
                    <List.Item className="no-items">
                      <List.Content style={{ marginLeft: 0 }}>
                        <List.Description style={{ textAlign: 'center' }}>
                          <Header as="h2" icon textAlign="center">
                            <Header.Content>
                              Pas de publication pour l'instant.
                            </Header.Content>
                          </Header>
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  )}
                  {/*	 
									<List.Item className="pending">
										<Container textAlign="right" className="toolbar">
											<Popup trigger={	<Button  circular basic inverted icon='question' size="mini" className="help" onClick={()=>this.openTour()}  />} content="Afficher l'aide" />
											<Popup trigger={	<Button  circular basic inverted icon='exclamation' size="mini" className="info"  />} content='Publication en attente de validation' />
											<Popup trigger={	<Button  circular basic icon='pencil' size="mini" className="edit"  />} content='Modifier cette publication' />
											<Popup trigger={	<Button  circular basic icon='check' size="mini" className="check" />} content='Valider cette publication' />
										</Container>
										<Image avatar src={com} size='tiny' />
										<List.Content>
											<List.Header  className={"color-com"}>Service Communication <span className="date"> Il y a 3 minutes </span>  </List.Header>
											<List.Description >
												Point d'étape sur le quartier Baltiss @Voiron_Ville - Réunion de chantier  pour les équipes PluralisHabitat et EiffageImmo 
												Au programme agrandissement salons, création balcons & ascenseurs... Réhabilitation "Baltiss'Avenir" ça avance!  
											</List.Description>

											<Grid columns={1}  className=" mosaic mosaic-1">
												<Grid.Row  className='mosaic-row'>
													<Grid.Column width={16}>
													<div  className="cover first" onClick={()=>this.handleOpen('https://picsum.photos/600/400?image=3')} style={{backgroundImage:'url(https://picsum.photos/600/400?image=3)'}}/>
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</List.Content>
									</List.Item>
								
									<List.Item>
									<Container textAlign="right" className="toolbar">
										<Popup trigger={	<Button  circular basic icon='pencil' size="mini" className="edit"  />} content='Modifier cette publication' />
										</Container>
										<Image avatar src={rh} size='tiny' />
										<List.Content>
											<List.Header className={"color-rh"}>Service Ressources Humaines <span className="date">12/04/2019</span> </List.Header>
											<List.Description >
												Point d'étape sur le quartier Baltiss @Voiron_Ville - Réunion de chantier  pour les équipes PluralisHabitat et EiffageImmo 
												Au programme agrandissement salons, création balcons & ascenseurs... Réhabilitation "Baltiss'Avenir" ça avance!  
											</List.Description>
											<Grid columns={2}  className=" mosaic mosaic-2">
												<Grid.Row stretched className='mosaic-row'>
													<Grid.Column width={8}>
													<div  className="cover first" onClick={()=>this.handleOpen('https://picsum.photos/600/400?image=4')} style={{backgroundImage:'url(https://picsum.photos/600/400?image=4)'}}/>
													</Grid.Column>
													<Grid.Column width={8}>
													<div   className="cover second" onClick={()=>this.handleOpen('https://picsum.photos/600/400?image=11')} style={{backgroundImage:'url(https://picsum.photos/600/400?image=11)'}}/>
													</Grid.Column>
												</Grid.Row>
											</Grid>
										</List.Content>
									</List.Item>
							*/}
                </List>
              </Ref>
            </Grid.Column>
            <Grid.Column
              only="tablet computer"
              tablet={4}
              computer={3}
              className="right-column"
            >
              <Sticky context={this.contextRef} pushing>
                <Menu vertical>
                  <Menu.Item
                    as={Link}
                    to="/"
                    onClick={() => this.props.handleSidebarHide()}
                  >
                    <style>{`
											.ui.label>.icon.home{
											margin-right:-5px;
											font-size: 1.5em;
											margin-top:-2px;
											}
										`}</style>
                    <Label
                      className="color-pluralis"
                      circular
                      icon="home"
                      style={{ backgroundColor: 'transparent' }}
                    />
                    ACCUEIL
                  </Menu.Item>
                </Menu>
                <Menu vertical>
                  <DateMenu
                    content="months"
                    postsCount={this.state.postsCount}
                    year={this.props.match.params.year}
                    month={this.props.match.params.month}
                    handleSidebarHide={this.props.handleSidebarHide}
                  />
                </Menu>
                <Menu vertical>
                  <DateMenu
                    content="years"
                    postsCount={this.state.postsCount}
                    year={this.props.match.params.year}
                    month={this.props.match.params.month}
                    handleSidebarHide={this.props.handleSidebarHide}
                  />
                </Menu>
              </Sticky>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    )
  }
}

export default Main
